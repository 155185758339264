import { AppManifest } from '@wix/platform-editor-sdk';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { configurePages, configPagesTabs } from './manifest/configurePages';
import { configureManagementActions } from './manifest/configureManagementActions';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { setCustomManifest } from './manifest/customizeManifest';
import { getCollectionsLength } from '../services/dataServices';

export const modifyAppManifest = async (
  options,
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const { t } = flowAPI.translations;
  const { httpClient } = flowAPI;
  const baseManifest = await fetchWidgetsStageData(options);

  const pages = (pagesBuilder) =>
    configurePages(editorSDK, pagesBuilder, t, flowAPI);
  const pagesTab = (pagesTabBuilder) => configPagesTabs(pagesTabBuilder, t);
  const managementActions = (managementActionsBuilder) =>
    configureManagementActions(managementActionsBuilder, t);

  const collectionsLength = await getCollectionsLength(httpClient);
  appManifestBuilder
    .withJsonManifest(baseManifest)
    .configurePages(pages)
    .configurePagesTab(pagesTab)
    .configureManagementActions(managementActions);
  const appManifest = appManifestBuilder.build();
  setCustomManifest(appManifest, t, collectionsLength);
  return appManifest;
};
