import { getProjectElementsData } from '../components/projectPageWidget/panel/showHideData';
import { getFeaturedCollectionElementsData } from '../components/featuredCollectionWidget/panel/showHideData';
import { getCollectionElementsData } from '../components/collectionPageWidget/panel/showHideData';
import { getPortfolioElementsData } from '../components/portfolioPageWidget/panel/showHideData';
import { MainWidgetsIds } from '../constants';
import { FlowEditorSDK, IHttpClient } from '@wix/yoshi-flow-editor';
import { ComponentRef } from '@wix/platform-editor-sdk';
import { FeaturedCollectionWidgetWidgetProps } from '../components/featuredCollectionWidget/config/types';
import { getCollections, getPortfolioSettings } from '../services/dataServices';
import { IPanelsApi } from '@wix/blocks-widget-services/dist/types/panels';

interface OpenPortfolioSettingsPanelConfig {
  httpClient: IHttpClient;
  editorSDK: FlowEditorSDK;
  componentRef: ComponentRef;
  panelsApi: IPanelsApi;
  panelIdentifier: string;
  t: any;
}

export const getPanelData = async (
  t: any,
  editorSDK: FlowEditorSDK,
  currentWidgetId: string,
  widgetRef: ComponentRef,
) => {
  let panelData = {
    categoriesData: [] as any,
    elementsData: [] as any,
  };
  switch (currentWidgetId) {
    case MainWidgetsIds.PORTFOLIO_PAGE:
      panelData = getPortfolioElementsData(t);
      break;
    case MainWidgetsIds.COLLECTION_PAGE:
      panelData = getCollectionElementsData(t);
      break;
    case MainWidgetsIds.PROJECT_PAGE:
      panelData = getProjectElementsData(t);
      break;
    case MainWidgetsIds.FEATURE_COLLECTION:
      const props = (await editorSDK.application.appStudioWidgets.props.get(
        '',
        {
          widgetRef,
        },
      )) as FeaturedCollectionWidgetWidgetProps;
      const numOfSelectedCollections =
        props.data?.selectedCollections?.length || 0;
      panelData = getFeaturedCollectionElementsData(
        t,
        numOfSelectedCollections,
      );
      break;

    default:
      break;
  }
  return panelData;
};

export const openPortfolioSettingsPanel = async ({
  httpClient,
  editorSDK,
  componentRef,
  panelsApi,
  panelIdentifier,
  t,
}: OpenPortfolioSettingsPanelConfig) => {
  const collections = await getCollections(httpClient);
  const isSubMenuEnabled = (await getPortfolioSettings(httpClient)).data
    .portfolioSettings?.siteMenuSettings?.autoUpdateMenu;
  const isSingleCollectionPortfolio = collections.length === 1;
  const existingProps = await editorSDK.application.appStudioWidgets.props.get(
    'token',
    { widgetRef: componentRef },
  );
  editorSDK.application.appStudioWidgets.props.set('token', {
    widgetRef: componentRef,
    newProps: {
      ...existingProps,
      subMenuPanelText: {
        toggleLabel: t('Settings_GFPP_Submenu_ToggleLabel'),
        enabled: isSingleCollectionPortfolio
          ? t('Settings_GFPP_Submenu_Enabled_SingleCollection')
          : t('Settings_GFPP_Submenu_Enabled_MultipleCollections'),
        disabled: t('Settings_GFPP_Submenu_DisabledText'),
      },
      isSubMenuEnabled,
    },
  });
  await panelsApi.openBlocksPanel(panelIdentifier, componentRef);
};
