import type {
  PageRef,
  ComponentRef,
  RouterRef,
} from '@wix/platform-editor-sdk';
import { createWidgetDefinition } from './createWidgetDefinition';
import { createSectionDefinition } from './createSectionDefinition';
import { InstallAppData, PresetWidget } from '../types/types';
import {
  classicDefaultInstallationSize,
  defaultBreakpoints,
} from '../const/pagesConsts';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const createAppPage = async ({
  editorSDK,
  isResponsive,
  appDefId,
  pageData,
  shouldAddMenuItem = false,
  shouldNavigateToPage = false,
  t,
}: InstallAppData): Promise<PageRef> => {

  const ppPageRef = await addWidgetAsPage({
    editorSDK,
    isResponsive,
    appDefId,
    pageData,
    shouldAddMenuItem,
    shouldNavigateToPage,
    t,
  });
  if (!isResponsive) {
    await editorSDK.document.transactions.runAndWaitForApproval(
      '',
      async () => {
        await addWidgetWithPresets({ editorSDK, ppPageRef, pageData });
      },
    );
  }
  return ppPageRef;
};

const addWidgetAsPage = async ({
  editorSDK,
  isResponsive,
  appDefId,
  pageData,
  shouldAddMenuItem = false,
  shouldNavigateToPage = false,
  t,
}: InstallAppData): Promise<PageRef> => {
  const { title, pageId, widgetId, presetId, mobilePresetId, pageUriSEO } =
    pageData;
  let pageRef: PageRef | null;
  await editorSDK.document.transactions.runAndWaitForApproval(
    'token',
    async () => {
      pageRef = await editorSDK.pages.add('', {
        title: t(title),
        definition: {
          id: '',
          type: 'Page',
          components: isResponsive
            ? [
                createSectionDefinition([
                  createWidgetDefinition({
                    appDefinitionId: appDefId,
                    widgetId,
                    presetId,
                    mobilePresetId,
                  }),
                ]) as any,
              ]
            : [],
          data: {
            managingAppDefId: appDefId,
            tpaPageId: pageId,
            pageUriSEO,
          },
          breakpoints: isResponsive ? defaultBreakpoints : undefined,
          componentType: 'mobile.core.components.Page',
        },
        shouldAddMenuItem,
        shouldNavigateToPage,
      });
    },
  );
  await editorSDK.document.transactions.runAndWaitForApproval(
    'token',
    async () => {
      pageRef &&
        (await editorSDK.document.pages.setState('', {
          state: { [pageId]: [pageRef] },
        }));
    },
  );
  // @ts-expect-error
  return pageRef;
};

const addWidgetWithPresets = async ({
  editorSDK,
  ppPageRef,
  pageData,
}: PresetWidget): Promise<ComponentRef> => {
  const { widgetId, presetId, mobilePresetId } = pageData;

  const pageChildren = await editorSDK.components.getChildren('', {
    componentRef: ppPageRef as ComponentRef,
  });

  const containerRef = pageChildren[0];

  const widgetRef = await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId,
    layout: {
      docked: {
        left: {
          px: 0,
          vw: 0,
        },
        right: {
          px: 0,
          vw: 0,
        },
      },
      x: 0,
      y: 0,
      fixedPosition: false,
      width: classicDefaultInstallationSize.width,
      height: classicDefaultInstallationSize.height,
      scale: 1,
      rotationInDegrees: 0,
    } as any,
    scopedPresets: {
      desktop: { layout: presetId, style: presetId },
      mobile: { layout: mobilePresetId, style: mobilePresetId },
    },
    installationType: 'closed',
    containerRef: containerRef || (ppPageRef as ComponentRef),
  });
  return widgetRef;
};

export const initPortfolioRouterPrefix = async (
  editorSDK: FlowEditorSDK,
  _prefix: string,
): Promise<RouterRef> => {
  const portfolioPagesData = await editorSDK.pages.getApplicationPages('');
  let prefix = _prefix;
  let checkValidity = true;
  let i = 1;
  while (checkValidity) {
    const isValidPrefix = !(
      await editorSDK.routers.isValidPrefix('', { prefix })
    ).valid;
    const existingRouter = !!(await editorSDK.routers.getByPrefix('', {
      prefix,
    }));
    checkValidity = isValidPrefix || existingRouter;
    if (checkValidity) {
      prefix = _prefix + i;
      i++;
    } else {
      checkValidity = false;
    }
  }
  const config = { portfolioPagesData };
  const routerRef = await editorSDK.routers.add('', {
    prefix,
    config,
  });
  return routerRef;
};

export const addPortfolioPageToMenu = async (
  editorSDK: FlowEditorSDK,
  portfolioRouterRef: string,
  label: string,
) => {
  const menuId = await editorSDK.menu.getDefaultMenuId('');
  await editorSDK.document.menu.addItem('', {
    menuId,
    menuItem: {
      link: {
        innerRoute: '/',
        routerId: portfolioRouterRef,
        target: '_self',
        type: 'DynamicPageLink',
      },
      label,
    },
  });
};

export const addPageToRouter = async (
  editorSDK: FlowEditorSDK,
  routerRef: RouterRef,
  pageRef: PageRef,
  pageRoles: string,
): Promise<void> => {
  await editorSDK.document.routers.pages.connect('', {
    pageRef,
    routerRef,
    pageRoles: [pageRoles],
  });
};
