import { defaultHelpId } from '../../constants';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';

const addActionsByEditor = (
  editorSDK: FlowEditorSDK,
  stateBuilder,
  t,
  flowAPI: EditorScriptFlowAPI,
) => {
  const isResponsive = flowAPI.environment.isEditorX;
  if (isResponsive) {
    stateBuilder.addAction({
      title: t('Page_Builder_Page_Action_Rename'),
      icon: 'page_rename',
      onClick: (e) => {
        const { pageRef } = e.detail;
        editorSDK.editor.openPagesPanel('', {
          pageRef,
          renameEnabled: true,
          initialSettingsTabType: 'PAGE_INFO',
        });
      },
    });
  } else {
    stateBuilder.addAction(
      {
        type: 'page_rename',
      },
      {
        type: 'page_set_as_homepage',
      },
    );
  }
};

const configurePortfolioPageState = (
  editorSDK: FlowEditorSDK,
  pagesBuilder,
  t,
  flowAPI: EditorScriptFlowAPI,
) => {
  pagesBuilder.configureState('Portfolio', (stateBuilder) => {
    stateBuilder.set({
      icon: 'page',
      orderIndex: 2,
    });

    addActionsByEditor(editorSDK, stateBuilder, t, flowAPI);

    stateBuilder.addAction({
      title: t('Page_Builder_Settings_Tab_Action_Delete_App'),
      icon: 'delete',
      event: 'deletePortfolioApp',
    });
  });
};
const configureCollectionPageState = (pagesBuilder, t) => {
  pagesBuilder.configureState('Collection', (stateBuilder) => {
    stateBuilder.set({
      icon: 'page',
      orderIndex: 1,
    });
  });
};
const configureProjectPageState = (pagesBuilder, t) => {
  pagesBuilder.configureState('Project', (stateBuilder) => {
    stateBuilder.set({
      icon: 'page',
      orderIndex: 0,
    });
  });
};

export const configurePages = (
  editorSDK: FlowEditorSDK,
  pagesBuilder,
  t,
  flowAPI: EditorScriptFlowAPI,
) => {
  // the actions for each page
  pagesBuilder.set({
    icon: 'page',
  });
  configurePortfolioPageState(editorSDK, pagesBuilder, t, flowAPI);
  configureCollectionPageState(pagesBuilder, t);
  configureProjectPageState(pagesBuilder, t);

  pagesBuilder.addSettingsTab(
    {
      type: 'page_info',
    },
    {
      type: 'layout',
    },
    {
      type: 'permissions',
    },
  );
};

export const configPagesTabs = (pagesTabBuilder, t) => {
  pagesTabBuilder
    .set({
      helpId: defaultHelpId,
      displayName: t('Pages_Tab_Builder_App_Name'),
    })
    .setGrouping('none');
};

export const getPortfolioPageStateSettings = (t: any) => [
  {
    type: 'page_info',
  },
  {
    type: 'layout',
  },
  {
    type: 'permissions',
  },
  {
    title: t('Page_Builder_Settings_Tab_Page_Seo'),
    type: 'seo',
  },
];
