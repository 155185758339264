import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import { defaultHelpId } from '../../constants';
import {
  openDashboardProjects,
  openDashboardCollections,
} from '../../utils/dashboardUtils';
import { setWidgetDesignTabs } from '../../utils/manifestUtils';
import {
  PortfolioPageWidgetPresets,
  PortfolioPageWidgetTabsArray,
} from './config/constants';

const setDesktopGFPP = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t,
  removeCollectionsPage: boolean,
  enableSubmenuSettingsPanel?: boolean,
) => {
  const gfpp = controllerBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Portfolio_Page_Widget_Gfpp_Main_Action_1'),
      onClick: openDashboardProjects(editorSDK),
    })
    .set('mainAction2', {
      label: t('Portfolio_Page_Widget_Gfpp_Main_Action_2'),
      onClick: openDashboardCollections(editorSDK, removeCollectionsPage),
    })
    .set('layout', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('add', { actionId: 'openElementsPanel' })
    .set('help', { id: defaultHelpId });
  enableSubmenuSettingsPanel &&
    gfpp.set('settings', {
      actionId: 'openPortfolioPageWidgetSettingsPanel',
    });
};

const setMobileGFPP = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t,
  removeCollectionsPage: boolean,
) => {
  controllerBuilder
    .gfpp('mobile')
    .set('mainAction1', {
      label: t('Portfolio_Page_Widget_Gfpp_Main_Action_1'),
      onClick: openDashboardProjects(editorSDK),
    })
    .set('mainAction2', {
      label: t('Portfolio_Page_Widget_Gfpp_Main_Action_2'),
      onClick: openDashboardCollections(editorSDK, removeCollectionsPage),
    })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('design', { behavior: 'HIDE' })
    .set('help', { id: defaultHelpId });
};

const setWidgetDesignPanel = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t,
  isEditorX: boolean,
  enablePortfolioPagePresets: boolean,
) => {
  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    const designBuilder = widgetDesignBuilder.set({
      title: t('Portfolio_Page_Widget_Design_Panel_Title'),
    });

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();

    if (enablePortfolioPagePresets) {
      designBuilder.setPresets(PortfolioPageWidgetPresets);
    }

    setWidgetDesignTabs(
      widgetDesignTabsBuilder,
      PortfolioPageWidgetTabsArray,
      t,
      isEditorX,
    );
  });
};

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  const { isEditorX } = flowAPI.environment;
  const enablePortfolioPagePresets = flowAPI.experiments.enabled(
    'specs.portfolio.enablePortfolioPagePresets',
  );
  const removeCollectionsPage = flowAPI.experiments.enabled(
    'specs.portfolio.removeCollectionsPage',
  );
  controllerBuilder.set({
    displayName: t('Widget_Name_Portfolio_Page_Widget'),
    nickname: 'portfolioPageWidget',
  });
  controllerBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    pinnable: false,
  });
  const enableSubmenuSettingsPanel = flowAPI.experiments.enabled(
    'specs.portfolio.enableSubmenuSettingsPanel',
  );
  setDesktopGFPP(controllerBuilder, editorSDK, t, removeCollectionsPage, enableSubmenuSettingsPanel);
  setMobileGFPP(controllerBuilder, editorSDK, t, removeCollectionsPage);
  setWidgetDesignPanel(
    controllerBuilder,
    editorSDK,
    t,
    isEditorX,
    enablePortfolioPagePresets,
  );
};
