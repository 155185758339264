import {
  EditorReadyOptions,
  AppManifest,
  ContextParams,
} from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { onEditorReady } from './editor-app/editorReady';
import { modifyAppManifest } from './editor-app/modifyAppManifest';

export function editorReady(
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> {
  return onEditorReady(editorSDK, appDefId, options, flowAPI);

}

export const getAppManifest = async (
  options,
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
  flowAPI: EditorScriptFlowAPI,
): Promise<AppManifest> => {
  return modifyAppManifest(options, editorSDK, flowAPI);
};

export const exports = (editorSDK: FlowEditorSDK) => ({
  editor: {
    getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
      const preset = await editorSDK.application.appStudioWidgets.getPreset(
        '',
        {
          componentRef: widgetRef,
        },
      );
      return `externalPreset-${preset.style}`;
    },
  },
});
